import { css } from "@emotion/react";
import styled from "@emotion/styled";

type WrapperProps = {
  $isMinify?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: inline-block;

  block-size: 44px;

  ${props =>
    props.$isMinify &&
    css`
      block-size: auto;
    `}
`;

export const Container = styled.div`
  display: inline-flex;
`;
