import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Player } from "@lottiefiles/react-lottie-player";

import type { HeadphoneIconTheme } from ".";
import IconHeadphoneSvg from "./headphone.svg";
import IconSpinnerSvg from "./spinner.svg";

export const StyledIconHeadphoneSvg = styled(IconHeadphoneSvg)`
  inline-size: 24px;
`;

type Props = {
  $isPaused?: boolean;
  $theme?: HeadphoneIconTheme;
};

export const IconContainer = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => {
    if (props.$isPaused) {
      switch (props.$theme) {
        case "black":
          return css`
            ${StyledIconHeadphoneSvg} {
              path {
                fill: #000000;
              }
            }
          `;
        default:
          return css`
            ${StyledIconHeadphoneSvg} {
              path {
                fill: #666666;
              }
            }
          `;
      }
    }
  }}
`;

export const StyledPlayer = styled(Player)`
  display: flex;

  inline-size: 24px;
  block-size: 22px;
`;

export const StyledIconSpinnerSvg = styled(IconSpinnerSvg)`
  inline-size: 24px;
  block-size: 24px;
`;
